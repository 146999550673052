import React from 'react'

const Logo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    data-name="Layer 2"
    viewBox="0 0 223.1 222"
    {...props}
  >
    <defs>
      <style>{'.cls-1{fill:#fff}'}</style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <path
        d="m192.92 32.1.62 30.88L223.1 80.6l-.23 65.19-29.33 17.16-.62 26.88-55.58 31.99c-1.18.43-2.18.01-3.31-.29-5.1-1.4-19.73-12.97-23.42-12.61-8.45 3.95-16.04 10.41-25.01 13.08l-55.61-32.46c-2.78-3.44.54-21.25-1.41-26.59L2.1 147.43l-1.86-2.14-.23-62.88 1.03-2.97 27.89-15.61c.96-1.33-.4-24.04.08-28.42.12-1.11.09-2.18.97-3.03C48.81 21.56 67.3 10.07 86.55 0l25 13.65c7.26-3.55 14.61-9.61 21.79-12.93.98-.45 1.84-.96 2.98-.61 19.12 10.19 37.36 22.12 56.59 31.98Zm-86.87 76.86v-85.5c-5.05-3.4-11.79-8.2-17.3-10.74-.98-.45-1.84-.96-2.98-.61L40.22 38.13c-.69.99-.54 22.65-.04 24.18.25.76 5.87 4.33 6.57 4.49 1.65.38 3.37-1.14 5.56-.71 10.65 2.1 8.6 20.99-5.33 16.42-3.45-1.13-3.16-4.53-5.41-6.59-.73-.66-6.25-3.87-6.86-3.81-4.36.39-19.48 12.15-24.67 14.35v22.5c4.9 1.72 7.61-2.09 10.7-2.81 8.08-1.9 14.83 7.39 8.84 13.85-6.99 7.55-11.75-1.67-19.54-.04v19.5c0 1.43 21.56 12.63 24.54 14.53 8.52-.1 9.82-13.36 19.2-9.27 7.86 3.42 6.8 15.14-1.3 16.21-2.42.32-4.96-.79-6.55-.47-.4.08-5.58 2.37-5.76 2.63-.53.75-.39 16.11-.19 18.42.1 1.12-.01 2.2 1.06 2.94l44.95 26.1c1.65.43 16.41-10.62 20.04-10.59v-80c-6.89-1.49-9.32 4.36-16.3 2.8-5.78-1.29-7.18-8.87-3.71-13.31 6.4-8.19 12.14 1.29 20.01-.49Zm106 0v-22.5c-4.38-2.09-21.07-14.16-24.5-14.16-.64 0-5.81 2.6-6.47 3.19-2.38 2.13-1.95 6.2-6.73 7.27-13.58 3.04-14.83-14.57-4.58-16.64 2.51-.51 4.31.88 6.3.42 1.54-.36 5.35-2.69 5.81-4.25.81-2.74.54-18.18.23-21.88-.09-1.12 0-2.2-1.03-2.97l-44.75-25.35-2.21.36-18.06 11.01v85.5c6.13 2.07 10.24-3.89 15.27-2.82 10.79 2.3 8.64 18.64-3.72 16.79-3.53-.53-6.51-4.73-11.55-2.97v80c5.66.39 16.5 11.2 21.27 9.84l43.73-25.34c1.07-.74.96-1.82 1.06-2.94.2-2.32.34-17.67-.19-18.42-.18-.26-5.36-2.54-5.76-2.63-3.29-.67-6.58 2.36-10.77-1.84-7.55-7.56 3.97-20.05 12.64-12.14 1.53 1.4 1.6 3.88 3.07 4.94.96.7 6.07 2.61 7.25 2.4 2.88-2.3 23.72-12.68 23.72-14.37v-19.5c-7.01-1.66-8.35 4.37-15.3 2.79-7.98-1.81-7.95-14.81 0-16.6 6.13-1.38 8.86 4.5 15.29 2.81Z"
        className="cls-1"
      />
      <path
        d="M77.72 43.29c2.23 2.27 1.71 5.09 2.57 6.44.74 1.17 5.14 2.14 5.76 5.74-1.07 9.81 1.47 22.61.02 32.01-.12.81-.29 1.5-1.02 1.98l-19.82 12.68-.23 24.19 20.6 9.58c.97 1.37.9 30 .5 33.61-.52 4.66-5.16 5.52-6.53 8.47-1.15 2.45-.12 4.84-2.49 7.51-6.12 6.89-18.7-1.22-13.89-9.9 3.37-6.09 6.91-3.39 9.9-5.1 3.83-2.2 1.13-22.94 2.02-27.9-3.08-2.17-20.12-7.86-20.91-10.27.85-10.35-1.64-23.93-.15-33.87.12-.81.27-1.52.99-2.01L75.1 83.31c-.75-3.31.89-24.47-.03-25.34-.33-.31-4.94.61-8.5-2-10.59-7.74 2.7-21.31 11.17-12.67ZM147.05 57.96c1.07 5.71-1.64 20.63.51 25 1.83 3.71 16 9.94 19.49 13.51 1.04 1.05.94 1.61 1.06 2.94.9 10.44-.65 22.31-.2 32.91-.79 2.43-17.65 8.32-20.92 10.23 1.2 6.77-1.5 18.68.25 24.72 1.5 5.16 9.58 2.19 12.05 7.95 4.93 11.47-11.24 18.35-15.96 6.97-.49-1.19-.15-2.82-.8-4.21-1.35-2.92-6.01-3.79-6.54-8.46-1.15-10.29.85-22.88.19-33.42l20.91-9.77-.23-24.19-19.84-12.65c-.72-.49-.87-1.2-.99-2.01-1.44-9.61.93-22.81.2-32.82.73-2.47 4.12-3.16 5.27-4.73 2.33-3.19 1.05-7.98 7.09-8.9 15.32-2.32 13.69 19.68-1.55 16.94Z"
        className="cls-1"
      />
    </g>
  </svg>
)

export default Logo
